(function() {
	'use strict';

	//
	// Testimonials carousel
	//
	var testimonials = tns({
		"container": ".slider-testimonials",
		"controlsContainer": ".slider-testimonials-controls",
		"preventScrollOnTouch": "force",
		"controlsPosition": "bottom",
		"navPosition": "bottom",
		"gutter": 24,
		"slideBy": "page",
		"responsive": {
			"992": {
				"gutter": 32
			}
		}
	});

	// Create a slide counter bellow the Carousel
	avm.require('avast.web.slideCounter')(testimonials);

	// // VIDEO AUTOPLAY FIX GLOWEB-9377
	// // Select all elements with the class `.icon-play-l`
	// var playIcons = document.querySelectorAll('.icon-play-l');

	// // Add a click event listener to each selected element
	// playIcons.forEach(function(playIcon) {
	// 	playIcon.addEventListener('click', function() {
	// 		// Select the first element with the class `.modal-video`
	// 		const video = document.querySelector('.modal-video');

	// 		// Check if the video element exists
	// 		if (video) {
	// 			// Get the current `src` attribute of the video
	// 			const videoSrc = video.getAttribute('src');

	// 			// Add `autoplay=1` to the `src` to enable autoplay when clicked
	// 			video.setAttribute('src', videoSrc + '&autoplay=1');
	// 		}
	// 	});
	// });

})();